import FloatingCart from './floatingcart';
import { eckNotificationModal } from '../misc/eck-modal';

export default class ProductPage {
    constructor(addToCartUrl, linkCartUrl, cartLockedUrl, changeProductQuantityUrl, floatingCartUrl) {
        if (
            typeof addToCartUrl === 'undefined' ||
            typeof linkCartUrl === 'undefined' ||
            typeof cartLockedUrl === 'undefined' ||
            typeof changeProductQuantityUrl === 'undefined'
        ) {
            console.warn('WARNING! Parameters missing - Could not initialize ProductPage.'); // eslint-disable-line no-console
            return;
        }

        $('[data-toggle="tooltip"]').tooltip();

        this.addToCartUrl = addToCartUrl;
        this.linkCartUrl = linkCartUrl;
        this.cartLockedUrl = cartLockedUrl;
        this.floatingCartUrl = floatingCartUrl;
        this.changeProductQuantityUrl = changeProductQuantityUrl;

        // also bind to future elements dynamically added to page
        $('body').on('click', '.add-to-cart', event => {
            const ean = `${$(event.currentTarget).data('ean')}`;
            const isEBook = $(event.currentTarget).data('e-book');
            const href = $(event.currentTarget).attr('href');
            // ebook modal
            if ($(`input.isEbook.${ean}:checked`).length > 0 || isEBook === true) {
                this.addToCart(event.currentTarget);
                $('#ebook-notification').modal({ backdrop: 'static' });
            } else {
                this.addToCart(event.currentTarget);
            }
            event.preventDefault();
        });

        eckNotificationModal();

        $('.product__card-quantity').on('change', 'select', event => {
            const quantity = $(event.target).val();
            const ean = $(event.target).data('ean');
            const purchaseType = $(event.target).data('purchasetype');
            $.get(`${this.changeProductQuantityUrl}?ean=${ean}&purchaseType=${purchaseType}&quantity=${quantity}`, function (data) {
                $(`#product__card-delivery-and-stock-info-${ean}-${purchaseType}`).html(data);
            });
        });

        $('#second-hand-checkbox').on('change', event => {
            const target = $(event.currentTarget);
            const parent = target.closest('.product__card-option');

            if (parent.hasClass('product__card-option--selected')) {
                parent.removeClass('product__card-option--selected');
                $('#default-add-button').removeClass('d-none');
                $('.product__price-to').removeClass('text-gray-light');
                $('#second-hand-add-button').addClass('d-none');
                $('#second-hand-ribbon').addClass('d-none')
            } else {
                parent.addClass('product__card-option--selected');
                $('#default-add-button').addClass('d-none');
                $('.product__price-to').addClass('text-gray-light');
                $('#second-hand-add-button').removeClass('d-none');
                $('#second-hand-ribbon').removeClass('d-none')
            }
        })

        // Set the checkbox active and trigger the change event if the url contains "tweedehands"
        if (document.location.search.includes('secondhand')) {
            $('#second-hand-checkbox').prop("checked", true).change();
        }
    }

    addToCart(element) {
        const button = $(element);
        const ean = `${button.data('ean')}`.replace('\\', ''); // needs to be string for replace,

        const purchaseType = this.getPurchaseType(button, ean);

        if (typeof purchaseType === 'undefined' || purchaseType === null) {
            console.warn('WARNING! Could not find a checked radio button or a hidden field containing the purchase type.'); // eslint-disable-line no-console
            return;
        }

        const quantityById = `#quantity-${ean}-${purchaseType}`;
        const quantity = $(quantityById).length > 0 ? $(quantityById).val() : 1;

        const model = {
            quantity,
            ean: ean.replace(/\\/g, ''),
            purchaseType
        };

        const addToCartUrl = this.addToCartUrl;
        const linkCartUrl = this.linkCartUrl;
        const cartLockedUrl = this.cartLockedUrl;
        const floatingCartUrl = this.floatingCartUrl;

        $.post(addToCartUrl, model).done(result => {
            switch (
            result // eslint-disable-line default-case
            ) {
                case 'Success':
                case 'DigitalSuccess':
                case 'CombiSuccess':
                    ProductPage.reloadCart(linkCartUrl, floatingCartUrl);
                    window.toast(document.querySelector('[data-add-to-cart]').dataset.addToCart);
                    break;
                case 'CartLocked':
                    window.location.href = cartLockedUrl;
                    break;
                case 'InvalidQuantity':
                    break;
                case 'Failed':
                    window.toast(document.querySelector('[data-failed-add-to-cart]').dataset.failedAddToCart, 'danger');
                    break;
                case 'DigitalFailed':
                    window.toast(document.querySelector('[data-failed-to-add-digital-to-cart]').dataset.failedToAddDigitalToCart, 'danger');
                    break;
                case 'ServiceAlreadyAdded':
                    window.toast(document.querySelector('[data-failed-add-service-to-cart]').dataset.failedAddServiceToCart, 'warning');
                    break;
                case 'SoldOut':
                    window.toast(document.querySelector('[data-soldout-add-to-cart]').dataset.soldoutAddToCart, 'warning');
                    break;
            }
        });
    }

    getPurchaseType(element, ean) {
        const purchaseTypeCheckbox = element.parent().find('input[type="radio"]:checked'); // Search results
        const purchaseTypeHiddenInput = element.parent().find('input[type="hidden"]'); // Search results
        const buttonPurchaseType = element.data('purchasetype'); // Product detail page & Home page (promo products)

        let purchaseTypeValue = null;
        if (purchaseTypeCheckbox.length) {
            purchaseTypeValue = purchaseTypeCheckbox.val();
        } else if (purchaseTypeHiddenInput.length === 1) {
            purchaseTypeValue = purchaseTypeHiddenInput.val();
        } else if (typeof buttonPurchaseType !== 'undefined') {
            purchaseTypeValue = buttonPurchaseType;
        }
        return purchaseTypeValue;
    }

    static reloadCart(linkCartUrl, floatingCartUrl) {
        $('#link-cart').load(linkCartUrl);
        if (typeof floatingCartUrl !== 'undefined') {
            const floatingCart = new FloatingCart(floatingCartUrl);
            var floatingCartId = typeof $('.floating-cart').parent().attr('id') !== 'undefined'
                ? $('.floating-cart').parent().attr('id')
                : 'cross-sell-cart';
            floatingCart.reloadFloatingCart(floatingCartId);
        }
    }
}