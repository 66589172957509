export default class FloatingCart {
    constructor(floatingCartUrl) {
        if (typeof floatingCartUrl === "undefined") {
            console.warn("WARNING! Parameters missing - Could not initialize FloatingCart."); // eslint-disable-line no-console
            return;
        }
        this.floatingCartUrl = floatingCartUrl;
    }

    reloadFloatingCart(elementId) {
        const element = document.getElementById(elementId);
        if (typeof element !== "undefined" && element !== null &&
            typeof this.floatingCartUrl !== "undefined" && this.floatingCartUrl !== null) {

            const noCacheHeaders = new Headers();
            noCacheHeaders.append("pragma", "no-cache");
            noCacheHeaders.append("cache-control", "no-cache");

            const fetchOptions = {
                method: 'GET',
                headers: noCacheHeaders,
                credentials: 'same-origin'
            };

            return fetch(this.floatingCartUrl, fetchOptions)
                .then(function(response) {
                    // handle HTTP response
                    if (response.status >= 200 && response.status < 400) {
                        return response.text();
                    }
                    return null;
                })
                .then(function(data) {
                    if (typeof data !== "undefined" && data !== null) {
                        const childElements = document.getElementsByClassName("floating-cart");
                        if (typeof childElements !== "undefined" && childElements !== null && childElements.length === 1) {
                            element.removeChild(childElements[0]);
                            const floatingCart = document.createRange().createContextualFragment(data);
                            element.appendChild(floatingCart);
                            return data;
                        }
                    }
                    return null;
                });
        }
        return null;
    }
}