import Html from '../misc/html';

export default class BooklistImporter {
    constructor(enabled, messages) {
        if (typeof messages === 'undefined') {
            console.warn('WARNING! Parameters missing - Could not initialize BooklistImporter.'); // eslint-disable-line no-console
            return;
        }

        this.maxLength = 65536; // 64kb
        this.messages = messages;
        this.messageWrapper = $('#booklistImport_notification_wrapper');
        this.messageField = $('#booklistImport_notification .notification__text');
        this.container = $('.custombooklistimport__container');
        this.inputField = $('#booklistText');
        this.hiddenField = $('#rawText');
        this.booklistSelector = $('#react-booklist-selector');

        if (enabled) {
            this.bindEvents();
        }
    }

    bindEvents() {
        $('#import-btn').on('click', (clickEvent) => {
            const importText = this.inputField.val();
            if (importText.length > 0 && importText.length < this.maxLength) {
                this.hiddenField.val(Html.htmlEncode(importText));
            } else {
                this.showMessage(this.messages.Required);
                clickEvent.preventDefault();
            }
        });

        $('.bookListSelector-alternative').on('click', (clickEvent) => {
            clickEvent.preventDefault();
            this.booklistSelector.hide();
            this.container.fadeIn(200);
        });

        $('#hideBooklistImportContainer').on('click', (clickEvent) => {
            if (this.booklistSelector.length > 0) {
                clickEvent.preventDefault();
                this.container.hide();
                this.messageWrapper.hide();
                this.booklistSelector.fadeIn(200);
            }
        });

        this.inputField.on('input', (event) => {
            const importText = $(event.target).val();
            if (importText.length > this.maxLength) {
                this.showMessage(this.messages.MaxSizeExceeded);
            } else if (importText.length > 0) {
                this.hideMessage();
            }
        });
    }

    showMessage(message) {
        if (typeof message !== 'undefined' && this.messageField.length > 0) {
            this.messageField.text(message);
            this.messageWrapper.fadeIn(300);
        }
    }

    hideMessage() {
        this.messageField.text('');
        this.messageWrapper.fadeOut(300);
    }

    show() {
        this.booklistSelector.hide();
        this.container.show();
    }
}
