import BooklistImporter from "../misc/booklist-importer";
import ProductPage from '../controllers/product';

const enableBooklistImporter = window.EnableImportBooklist === "true";
const booklistImportMessages = {
  Required: Resources.BooklistSelector.BooklistImport_Required,
  NotFound: Resources.BooklistSelector.BooklistImport_NotFound,
  MaxSizeExceeded: Resources.BooklistSelector.BooklistImport_MaxSizeExceeded
};

const booklistImporter = new BooklistImporter(enableBooklistImporter, booklistImportMessages);

const productPage = new ProductPage(
  window.ProductPageAddToCartUrl,
  window.ProductPageGetLinkCartUrl,
  window.ProductPageCartLockedUrl,
  window.ChangeProductQuantityUrl,
  window.floatingCartUrl
);

$(() => {
  if (window.location.href.indexOf("?booklistimport_result") > -1) {
    // For now the returned result doesn't matter, just show a notification.
    booklistImporter.show();
    booklistImporter.showMessage(booklistImportMessages.NotFound);
  }
});
