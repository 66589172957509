export function eckNotificationModal() {
    document.addEventListener(
        'click',
        function (event) {
            if ($(event.target).is('.eck-modal')) {
                event.preventDefault();
                const eckModal = document.querySelector('#eck-ecc-notification-modal');
                eckModal.querySelector('.bookname').innerHTML = event.target.dataset.title;
                eckModal.querySelector('.add-to-cart').dataset.ean = event.target.dataset.ean;
                eckModal.querySelector('.add-to-cart').dataset.purchasetype = event.target.dataset.purchasetype;
                //Jquery work around for the dataset issues
                $('#eck-ecc-notification-modal .add-to-cart').data('ean', event.target.dataset.ean);
                $('#eck-ecc-notification-modal .add-to-cart').data('purchasetype', event.target.dataset.purchasetype);
                $('#eck-ecc-notification-modal').modal('show');
            }
        },
        false
    );
}